import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed54bd14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "_pages" }
const _hoisted_2 = ["button_name", "onClick"]
const _hoisted_3 = { class: "_nav_right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Prompt = _resolveComponent("Prompt")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({_nav_basic:true, box_shadow:true, _nav_float:_ctx.scroll<50 && _ctx.window.innerWidth > 700, _nav_fix:true || _ctx.scroll>=50 || _ctx.window.innerWidth < 700})
  }, [
    _createElementVNode("img", {
      src: "https://huamei2001.oss-cn-shenzhen.aliyuncs.com/icons/%E5%8D%8E%E7%BE%8Elogo-%E7%99%BD%E8%89%B2.png",
      class: "_logo clickable",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goto('/'))),
      alt: ""
    }),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages.filter(x=> ( x.nav && x.pageNameCN != '联系\n我们') ), (p, ind) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "btn_wrap clickable",
          key: ind,
          button_name: p.data,
          onClick: ($event: any) => (_ctx.onClickTab(p.data, p.tabs[0]))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({_page:true, current:_ctx.route.name == p.data})
          }, [
            _createElementVNode("div", null, _toDisplayString(_ctx.store.state.currLanguage == 'cn' ? 
                            _ctx.windowWidth > 700?
                            p.pageNameCN.replace('\n', '') :
                            p.pageNameCN: 
                            p.pageNameEN), 1)
          ], 2)
        ], 8, _hoisted_2))
      }), 128)),
      (_ctx.windowWidth < 700 && _ctx.store.state.loginState == '')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "btn_wrap clickable",
            button_name: "contact",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickTab('contact', '')))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass({_page:true, current:_ctx.route.name == 'contact'})
            }, [
              _createElementVNode("div", null, _toDisplayString(_ctx.store.state.currLanguage == 'cn' ? '联系\n我们': 'Contact Us'), 1)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (_ctx.windowWidth < 700 && _ctx.store.state.loginState != '')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "btn_wrap clickable",
            button_name: "userHome",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickTab('userHome', '')))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass({_page:true, current:_ctx.route.name == 'userHome'})
            }, [
              _createElementVNode("div", null, _toDisplayString(_ctx.store.state.currLanguage == 'cn' ? '会员\n主页': 'User\nHome'), 1)
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.store.state.loginState == '')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: "_phone",
            to: '/contact-us'
          }, {
            default: _withCtx(() => [
              (_ctx.windowWidth > 700)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: _normalizeClass({_kid_image:true, clickable:true, popin:_ctx.loaded, fixAnimation:_ctx.fixAnimation}),
                    src: "https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E8%9B%87%E5%B9%B4.png",
                    alt: ""
                  }, null, 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass({_phone_button:true, clickable:true, current:_ctx.route.name == 'ContactUs'})
              }, " 联系我们 ", 2)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.store.state.loginState != '')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            class: "_phone",
            to: '/user-home'
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass({_phone_button:true, clickable:true, current:_ctx.route.name == 'ContactUs'})
              }, "会员主页", 2)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.showLogin)
          ? (_openBlock(), _createBlock(_component_Prompt, {
              key: 0,
              title: "登录账户",
              prompts: ['邮箱','密码'],
              type: "login",
              onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showLogin=false)),
              onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showLogin=false)),
              onConfirm: _ctx.confirmLogin,
              onRegister: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showLogin=false,_ctx.showRegister=true))
            }, null, 8, ["onConfirm"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.showRegister)
          ? (_openBlock(), _createBlock(_component_Prompt, {
              key: 0,
              title: "注册账户",
              prompts: ['邮箱','密码'],
              onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showRegister=false)),
              onCancel: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showRegister=false)),
              onConfirm: _ctx.confirmRegister,
              onRegister: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showRegister=true))
            }, null, 8, ["onConfirm"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}